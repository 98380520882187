const container=[{initial:"empty record"}]
const AlldefermentReducer=(state=container,action)=>{

// console.log("payload 2",action.payload)
  switch(action.type){

 case'AllDEFERMENT':
 return {...state,record:action.payload}

 default:
   return state;

  }
}


export default AlldefermentReducer;