import defermentr from './DefermentReducers';
import studentChedule from './studntscheduReducer'
import programRe from './programReducer'
import timeTable from './dlcttReducer';
import AlldefermentReducer from './AlldefermentReducer';
import studentsl from './StudentReducer'
import { combineReducers } from 'redux'


 const allReducers=combineReducers({
    deferment:defermentr,
    studentChedule:studentChedule,
    programs:programRe,
    timeTable:timeTable,
    Alldeferment:AlldefermentReducer,
    Studentslist:studentsl,
   
})


export default allReducers;  