import axios from "axios";



import setStudentSched from '../Actions/studentsScheduleAction'

  

export const  sexamSchedule=()=>{   
    
    
    return async function(dispatch){
     await   axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/studentexamSchedule.php").then((result)=>{
           // console.log("Rudux-Thung:",result.data)
            dispatch(setStudentSched(result.data))
              });
    

    }


}


