function setStudentSched(views){
    
    return {
    
        type:'EXAMSCHEDULE',
        payload:views,
    }
    
    }
    
    
    
    
    
    export default setStudentSched;
    
    
    