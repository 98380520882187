function setAlldefermentAction(views){
    
    return {
    
        type:'AllDEFERMENT',
        payload:views,
    }
    
    }

    
    
    
    
    
    
    export default setAlldefermentAction;
    
    
    