import axios from "axios";

import setAlldefermentAction from '../Actions/AlldefermentAction'


  

export const  apicall=()=>{   
    
    
    return async function(dispatch){
     await   axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/loaddeferments.php").then((result)=>{
           // console.log("Rudux-Thung:",result.data)
            dispatch(setAlldefermentAction(result.data))
              });
    

    }


}


