const container=[{initial:"empty students"}]
const studentsl=(state=container,action)=>{

// console.log("payload 2",action.payload)
  switch(action.type){

 case'STUDENTS':
 return {record:action.payload}

 default:
   return state;

  }
}


export default studentsl;