const container=[{initial:"empty Schedule"}]
const studentChedule=(state=container,action)=>{

// console.log("payload 2",action.payload)
  switch(action.type){

 case'EXAMSCHEDULE':
 return {record:action.payload}

 default:
   return state;

  }
}


export default studentChedule;