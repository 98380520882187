import axios from "axios";

import setProgram from '../Actions/ProgramAction'

  

export const  allProgramthunk=()=>{   
    
    
    return async function(dispatch){
     await   axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/loadprog.php").then((result)=>{
           // console.log("Rudux-Thung:",result.data)
            dispatch(setProgram(result.data))
              });
    

    }


}


