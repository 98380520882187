function setProgram(views){
    
    return {
    
        type:'PROGRAM',
        payload:views,
    }
    
    }
    
    
    
    
    
    export default setProgram;
    
    
    