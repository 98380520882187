import { createStore,applyMiddleware,compose} from 'redux'
import allReducers from '../Reducers/'
import reduxthunk from 'redux-thunk'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//const store= createStore(allReducers,window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__&& window.__REDUX_DEVTOOLS_EXTENSION__())
 const store= createStore(allReducers,applyMiddleware(reduxthunk))
 // store.dispatch(setProject())

     
 export default store;

